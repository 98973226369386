import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import './StreamList.css';

const languageDictionary = {
    'en': 'English',
    'fr': 'French',
    'de': 'German',
    'es': 'Spanish',
    'it': 'Italian',
    'pt': 'Portuguese',
    'ru': 'Russian',
    'jp': 'Japanese',
    'kr': 'Korean',
    'zh': 'Chinese',
    'ar': 'Arabic',
    'cs': 'Czech',
    'da': 'Danish',
    'nl': 'Nederlands',
};

const StreamList = () => {
    const [twitchStreams, setTwitchStreams] = useState([]);
    const [filteredTwitchStreams, setFilteredTwitchStreams] = useState([]);
    const [youtubeStreams, setYoutubeStreams] = useState([]);
    const [filteredYoutubeStreams, setFilteredYoutubeStreams] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showTwitch, setShowTwitch] = useState(true);
    const [showYoutube, setShowYoutube] = useState(true);
    const [availableLanguages, setAvailableLanguages] = useState(new Set());
    const [selectedLanguage, setSelectedLanguage] = useState('all');

    useEffect(() => {
        const fetchTwitchData = async () => {
            try {
                const twitchResponse = await fetch('/api/twitch');
                const data = await twitchResponse.json();
                setTwitchStreams(data);
                setFilteredTwitchStreams(data);
                updateAvailableLanguages(data);
            } catch (error) {
                console.error('Error loading Twitch streams:', error);
                setTwitchStreams([]);
                setFilteredTwitchStreams([]);
                setErrorMessage('There was an issue loading Twitch streams. Please try again later.');
            }
        };

        const fetchYoutubeData = async () => {
            try {
                const ytResponse = await fetch('/api/youtube/streams');
                const data = await ytResponse.json();
                setYoutubeStreams(data);
                setFilteredYoutubeStreams(data);
            } catch (error) {
                console.error('Error loading YouTube streams:', error);
                setYoutubeStreams([]);
                setFilteredYoutubeStreams([]);
                setErrorMessage('There was an issue loading YouTube streams. Please try again later.');
            }
        };

        fetchTwitchData();
        fetchYoutubeData();
    }, []);

    const updateAvailableLanguages = (twitchStreams) => {
        const languages = new Set(['all']);
        twitchStreams.forEach(stream => {
            if (stream.stream.language) {
                languages.add(stream.stream.language);
            }
        });
        setAvailableLanguages(languages);
    }

    const search = (event) => {
        const input = event.target.value.toLowerCase();
        setSearchTerm(input);

        if (showTwitch) {
            const filteredTwitch = twitchStreams.filter(stream =>
                (stream.stream.title && stream.stream.title.toLowerCase().includes(input)) ||
                (stream.user.name && stream.user.name.toLowerCase().includes(input)) ||
                (stream.user.login && stream.user.login.toLowerCase().includes(input))
            );
            setFilteredTwitchStreams(filteredTwitch);
        }

        if (showYoutube) {
            const filteredYoutube = youtubeStreams.filter(stream =>
                (stream.video.title && stream.video.title.toLowerCase().includes(input)) ||
                (stream.channel.name && stream.channel.name.toLowerCase().includes(input))
            );
            setFilteredYoutubeStreams(filteredYoutube);
        }
    };

    const handleLanguageChange = (event) => {
        const language = event.target.value;
        setSelectedLanguage(language);

        if (showTwitch) {
            const filteredTwitch = twitchStreams.filter(stream => {
                const matchesSearch = (
                    (stream.stream.title && stream.stream.title.toLowerCase().includes(searchTerm)) ||
                    (stream.user.name && stream.user.name.toLowerCase().includes(searchTerm)) ||
                    (stream.user.login && stream.user.login.toLowerCase().includes(searchTerm))
                );
                const matchesLanguage = language === 'all' || stream.stream.language === language;
                return matchesSearch && matchesLanguage;
            });
            setFilteredTwitchStreams(filteredTwitch);
        }
    }

    const handleRedirect = (stream) => {
        if (stream.type === 'Twitch') {
            window.open(`https://www.twitch.tv/${stream.user.login}`);
        } else if (stream.type === 'YouTube') {
            window.open(`https://www.youtube.com/watch?v=${stream.id}`);
        }
    };

    const handleToggleTwitch = () => {
        setShowTwitch(prev => !prev);
    };

    const handleToggleYoutube = () => {
        setShowYoutube(prev => !prev);
    };

    return (
        <Layout>
            <div className="main-container">
                <div className="left-controls">
                    <div id="toggles">
                        <input
                            type="checkbox"
                            id="toggleTwitch"
                            checked={showTwitch}
                            onChange={handleToggleTwitch}
                        />
                        <label htmlFor="toggleTwitch">
                            {showTwitch ? `Hide ${twitchStreams.length} Twitch Streams` : `Show ${twitchStreams.length} Twitch Streams`}
                        </label>
                        
                        <input
                            type="checkbox"
                            id="toggleYoutube"
                            checked={showYoutube}
                            onChange={handleToggleYoutube}
                        />
                        <label htmlFor="toggleYoutube">
                            {showYoutube ? `Hide ${youtubeStreams.length} YouTube Streams` : `Show ${youtubeStreams.length} YouTube Streams`}
                        </label>
                    </div>
                    
                    <div className="language-filter">
                        <select 
                            value={selectedLanguage} 
                            onChange={handleLanguageChange}
                        >
                            {Array.from(availableLanguages).map(lang => (
                                <option key={lang} value={lang}>
                                    {lang === 'all' ? 'All Languages' : languageDictionary[lang] || lang}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="title-search-container">
                    <div className='title'>
                        <h1>Stream List</h1>
                    </div>
                    <div className="search-container">
                        <input
                            type="text"
                            id="streamSearch"
                            placeholder="Search Streams"
                            value={searchTerm}
                            onChange={search}
                        />
                    </div>
                </div>

                <div className="filter-info">
                    <p>Here you can find all the skyblock related streams both from YouTube and Twitch!</p>
                    <p>Feel free to support and spread positivity to anyone!</p>
                    <p>The stream list updates every 15 minutes.</p>
                    <p>If your stream doesn't appear in the list, please make sure that:</p>
                    <p>Twitch - The title includes "hypixel skyblock"</p>
                    <p>Twitch - The game Minecraft is selected.</p>
                    <p>YouTube - The title contains "#hypixelskyblock" or "#hypixel #skyblock" tag</p>
                </div>
            </div>

            <h2 className="section-title">{showTwitch ? `Twitch Streams (${twitchStreams.length})` : ""}</h2>
            <div className="list-container">
                {showTwitch && (
                    <>
                        {filteredTwitchStreams.length === 0 && <p>No Twitch streams found.</p>}
                        {filteredTwitchStreams.map(stream => (
                            <div
                                key={stream.id}
                                className="item twitch-item"
                                onClick={() => handleRedirect({
                                    ...stream,
                                    type: 'Twitch',
                                    thumbnail_url: stream.stream.thumbnail_url
                                })}
                            >
                                <div className="item-content">
                                    <div className='item-thumbnail'>
                                        <img
                                            className="thumbnail"
                                            src={stream.stream.thumbnail_url || 'default-thumbnail-url.jpg'}
                                            alt={`${stream.stream.title || 'Stream Thumbnail'}`}
                                        />
                                        <div className='thumbnail-info-right'>
                                            <div className='info-box'>
                                                <span className='info'>
                                                    👀 {stream.stream.viewer_count?.toLocaleString() || "0"}
                                                </span>
                                            </div>
                                            <div className='info-box'>
                                                <span className='info'>
                                                    🌏 {languageDictionary[stream.stream.language] || 'Unknown'}
                                                </span>
                                            </div>
                                            <div className='info-box tag-hover-area'>
                                                <span className='info'>
                                                    {stream.stream.tags.length} tags
                                                </span>
                                                <div className='tag-content'>
                                                    {stream.stream.tags.map((tag, index) => (
                                                        <span key={index} className='tag'>#{tag}</span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='details'>
                                        <h4>{stream.stream.title || 'No Title'}</h4>
                                        <div className='item-footer'>
                                            <div className='icon-name'>
                                                <img
                                                    width="60"
                                                    height="60"
                                                    src={stream.user.profile_url || 'default-profile-icon.jpg'}
                                                    alt={`${stream.user.name || 'Streamer Icon'}`}
                                                />
                                                <h3>{stream.user.name || 'Unknown Streamer'}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
            <h2 className="section-title">{showYoutube ? `YouTube Streams (${youtubeStreams.length})` : ""}</h2>
            <div className="list-container">
                {showYoutube && (
                    <>
                        {filteredYoutubeStreams.length === 0 && <p>No YouTube streams found.</p>}
                        {filteredYoutubeStreams.map(stream => (
                            <div
                                key={stream.id}
                                className="item youtube-item"
                                onClick={() => handleRedirect({
                                    ...stream,
                                    type: 'YouTube',
                                    thumbnail_url: stream.video.thumbnail_url || 'default-thumbnail-url.jpg'
                                })}
                            >
                                <div className="item-content">
                                    <img
                                        className="thumbnail"
                                        src={stream.video.thumbnail_url || 'default-thumbnail-url.jpg'}
                                        alt={`${stream.video.title || 'Video Thumbnail'}`}
                                    />
                                    <h4>{stream.video.title || 'No Title'}</h4>
                                    <div className="item-footer">
                                        <div className="icon-name">
                                            <img
                                                width="60"
                                                height="60"
                                                src={stream.channel.profile_url || "../../assets/default-profile-icon.jpg"}
                                                alt={`${stream.channel.name || 'Channel Icon'}`}
                                            />
                                            <h3>{stream.channel.name || 'Unknown Channel'}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
            {errorMessage && <div id="error-message" className="error-message">{errorMessage}</div>}
        </Layout>
    );
};

export default StreamList;