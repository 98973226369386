import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import './Home.css';

const Home = ({ setConsent }) => {
  const [showPrivacyNotice, setShowPrivacyNotice] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('consentGiven');
    if (!consentGiven) {
      setShowPrivacyNotice(true);
    } else {
      setConsent(true);
    }
  }, [setConsent]);

  const handleAcceptPrivacy = () => {
    localStorage.setItem('consentGiven', 'true');
    setShowPrivacyNotice(false);
    setConsent(true);
  };

  const handleRejectPrivacy = () => {
    setShowPrivacyNotice(false);
    setConsent(false);
  };

  return (
    <Layout>
      <div className="home">
        <h1>SkyAli Home Page</h1>
        <div id='about-us' className='about-us'>
          <p></p>
        </div>
        {showPrivacyNotice && (
          <div id="privacy-notice" className="privacy-notice">
            <p>
              We use local storage to cache data and enhance your experience. No personal
              information is stored, and the data is only used to improve the functionality
              of the website. By continuing to browse, you agree to our use of local storage.{' '}
              <a href="/privacy-policy">Learn more</a>.
            </p>
            <div className="buttons">
              <button className="accept-button" onClick={handleAcceptPrivacy}>
                Accept
              </button>
              <button className="reject-button" onClick={handleRejectPrivacy}>
                Reject
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Home;
