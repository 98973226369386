import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import discordLogo from '../../assets/images/discord-mark-blue.png';
import skyAliLogo from '../../assets/images/SkyAliLogo.ico';

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <header className="header">
      <div className="header-content">
        <div className="site-name">
          <img src={skyAliLogo} alt="SkyAli Logo" className="skyali-logo" />
          <Link to="/">SkyAli</Link>
        </div>
        <nav className="nav">
          <ul className="nav-list">
            <li className={currentPath === '/' ? 'active' : ''}>
              <Link to="/">Home</Link>
            </li>
            <li className={currentPath === '/discord-list' ? 'active' : ''}>
              <Link to="/discord-list">Discord List</Link>
            </li>
            <li className={currentPath === '/stream-list' ? 'active' : ''}>
              <Link to="/stream-list">Stream List</Link>
            </li>
            <li className={currentPath === '/video-list' ? 'active' : ''}>
              <Link to="/video-list">Video List</Link>
            </li>
          </ul>
        </nav>
        <a href="https://discord.gg/ubvBzRUx3w" target="_blank" rel="noopener noreferrer" className="discord-button">
        <img src={discordLogo} alt="Discord Logo" className="discord-logo" />

          Join Discord
        </a>
      </div>
    </header>
  );
};

export default Header;
