import React, { useEffect, useState } from 'react';
import './Footer.css';

const Footer = () => {

  useEffect(() => {
  }, []);

  return (
    <footer className="footer">
      <p className="footer-text">&copy; 2024 SkyAli. All rights reserved.</p>
    </footer>
  );
};

export default Footer;