import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import DiscordList from './pages/DiscordList/DiscordList';
import StreamList from './pages/StreamList/StreamList';
import VideoList from './pages/VideoList/VideoList';

function App() {
  const [consent, setConsent] = useState(false);
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    Promise.all([
      fetch('/api/discord/servers'),
      fetch('/api/youtube/videos'),
      fetch('/api/youtube/streams'),
      fetch('/api/twitch')
    ])
      .then(async ([discordRes, videosRes, ytStreamsRes, twitchRes]) => {
        const discord = await discordRes.json();
        const videos = await videosRes.json();
        const ytStreams = await ytStreamsRes.json();
        const twitchStreams = await twitchRes.json();

        return {
          discord,
          videos,
          ytStreams,
          twitchStreams
        };
      })
      .then(data => {
        setInitialData(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setInitialData({}); // Set empty object to prevent endless loading
      });
  }, []);

  if (!initialData) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home setConsent={setConsent} />} />
        <Route 
          path="/discord-list" 
          element={<DiscordList consent={consent} servers={initialData.discord} />} 
        />
        <Route 
          path="/stream-list" 
          element={<StreamList ytStreams={initialData.ytStreams} twitchStreams={initialData.twitchStreams} />} 
        />
        <Route 
          path="/video-list" 
          element={<VideoList videos={initialData.videos} />} 
        />
      </Routes>
    </Router>
  );
}

export default App;